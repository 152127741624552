import React from "react";

import Editor from './AMiniSiteEditor'
import EditorWrapper from '../../EditorWrapper'

import MiniSite from "../../common/MiniSite";
import MiniSitePhotoOnTop from "../../common/MiniSitePhotoOnTop";
import EmptyArticle from "../../common/EmptyArticle";

const AMiniSite = props => {
    let article = EmptyArticle();
    const { settings } = props;
    const imageUrl = (settings['background-image'] ? settings['background-image'] : '');

    article.link = settings.link;
    article.sponsor = settings.sponsor;
    article.img = article.img = window.MEDIA_DOMAIN + '/' + imageUrl.replace('.', ',780,0,0,0.');
    article.title = settings.title;
    article.description = settings.description;

    return (
        <div className={props.classes} {...props.events} >
            <EditorWrapper {...props} editor={Editor} />
            <MiniSite
                article={article}
                {...props}
            />
        </div>
    );
};


export default AMiniSite;
